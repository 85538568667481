import { UserGroupEntity } from "domain/entity/userGroup/UserGroupEntity";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { INITIAL_USER_GROUP_COL_DEF } from "presentation/constant/UserGroup/UserGroupColumnDefinition";
import { UserGroupConstant } from "presentation/constant/UserGroup/UserGroupConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useUserGroupVM } from "presentation/hook/UserGroup/useUserGroupVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useUserGroupTracked } from "presentation/store/UserGroup/UserGroupProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HPHTable, Loader } from "veronica-ui-component/dist/component/core";

const UserGroupTablePanel:React.FC = () => {
    const [ userGroupState ] = useUserGroupTracked();
    const [ anaInfoSate ] = useANAInfoTracked();
    const messageBarVM = useMessageBarVM();
    const userGroupVM = useUserGroupVM();
    const USERGROUP_CONSTANT = UserGroupConstant.userGroup;
    const { isTabularDataActive, lastEditRowId } = userGroupState.userGroupState;
    const allFormState = userGroupState.userGroupState.allFormState;
    const [ isShowDelete, setIsShowDelete ] = useState<boolean>(false);
    const [ deletedRole, setDeletedRole ] = useState<UserGroupEntity>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const handleAddClick = useCallback(() => {
        userGroupVM.onUserGroupAddClick();
    }, [userGroupVM])

    const handleRowDoubleClick = useCallback((userGroup: UserGroupEntity) => {
        userGroupVM.onUserGroupRowDoubleClick(userGroup);
    }, [userGroupVM])

    const handleDelete = () => {
        setIsLoading(true);
        setIsShowDelete(false);
        if (!deletedRole) return;

        userGroupVM.onUserGroupRowDeleteClick(deletedRole).then((data) => {
            if (allFormState && allFormState["DeleteUserGroupFail"]) {
                messageBarVM.showError(allFormState["DeleteUserGroupFail"]?.toString());
            } else {
                messageBarVM.showSuccess(MessageConstant.common.DELETED_DATA_SUCCESSFUL);
                userGroupVM.loadAllUserGroups().then(data => {
                    setIsLoading(false);
                });
            }
        }).catch((error) => {
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const handleRowDeleteClick = useCallback(async(role: UserGroupEntity) => {
        setIsShowDelete(true);
        setDeletedRole(role);
    }, []);    

    const handleCancel = () => {
        setIsLoading(true);
        setIsShowDelete(false);
        userGroupVM.loadAllUserGroups().then(data => {
            setIsLoading(false);
        });
    }
    
    const memoUserGroupTable = useMemo(() => {
        return <HPHTable
            id='user-group-table'
            columns={INITIAL_USER_GROUP_COL_DEF}
            headerLabel={USERGROUP_CONSTANT.USERGROUP}
            onAddClick={handleAddClick}
            onRowDoubleClick={(e: any, userGroup: UserGroupEntity) => handleRowDoubleClick(userGroup)}
            data={userGroupState.userGroups.map((userGroup: UserGroupEntity) => ({
                ...userGroup,
                flag_edit: userGroup.resource === lastEditRowId,
            }))}
            showPaginator={false}
            editable={false}
            showAddIcon={anaInfoSate.allowCreate}
            showDeleteButton={anaInfoSate.allowDelete}
            showReloadIcon={false}
            onDelete={(deleteUserGroup: UserGroupEntity) => handleRowDeleteClick(deleteUserGroup)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 72px)" />;
    }, [USERGROUP_CONSTANT, userGroupState.userGroups, lastEditRowId, anaInfoSate.allowCreate, anaInfoSate.allowDelete, handleAddClick, handleRowDoubleClick, handleRowDeleteClick])

    useEffect(() => {
        userGroupVM.onShowLoading();
        const initialScreen = async() => {   
            try {
                const results = await Promise.allSettled([
                    userGroupVM.loadAllUserGroups(),
                    userGroupVM.loadDropdownOptions(anaInfoSate.operatingUnit)
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                      } else if (index === 1 && result.status === 'rejected') {

                      }
                })
            } catch(error) {
            }
        }
        initialScreen().then((data) => {            
            userGroupVM.onHideLoading();
        }).catch(error => {            
            userGroupVM.onHideLoading();
        });
    }, [userGroupVM, anaInfoSate.operatingUnit])

    if (userGroupState.isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>{isTabularDataActive && <TableWrapper>{memoUserGroupTable}</TableWrapper>}
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${UserGroupConstant.userGroup.USERGROUP}`} 
            contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
            visible={isShowDelete}
            onCancelClick={handleCancel}
            onDeleteClick={handleDelete}
        />
    </>;
}

export default UserGroupTablePanel;